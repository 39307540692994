// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import RailsUjs from "rails-ujs";
import Turbolinks from "turbolinks";
import axios from "axios";
// import Accordion from 'accordion-js';
// import 'accordion-js/dist/accordion.min.css';


RailsUjs.start();
Turbolinks.start();
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function (event) {
  handleFlashMessage();
  handleNav();
  handleSearchFilter();
  handleQuestion();
  handleHowto();
  handleModal();
  handleLevelDropDowns();
  handleLevelDropDownsEqual();
  handleTextareaAutoHeight();
  handleScrollBottom();
  handleReceivers();
  handleIsReceivers();
  handleCounterReceivers();
  handleCounterTeamNames();
  handleMaxReceiversWarning();
  smoothScroll();
  setupIndexOrderChangeHandler();
  setupSearchFormHandler();
  setupTeamCreateFormPrefectureHandler();
});

const handleFlashMessage = () => {
  const fadeTarget = document.querySelector(".js-fadeOut");
  if (!fadeTarget) {
    return
  }
  setTimeout(function () {
    fadeTarget.style.display = "none";
  }, 5000);
};

const handleNav = () => {
  const clickTarget = document.querySelector(".p-nav__icon");
  if (!clickTarget) {
    return
  }
  clickTarget.addEventListener("click", function (event) {
    clickTarget.classList.toggle("-active");
    document.querySelector(".p-nav__bg").classList.toggle("-open");
    document.querySelector(".p-nav__content").classList.toggle("-open");
  });
};



const handleSearchFilter = () => {
  const clickTarget_bg = document.querySelector(".p-search__bg");
  const clickTarget_btn = document.querySelector(".js-searchFilterToggle");
  if (!clickTarget_bg) {
    return
  }
  clickTarget_bg.addEventListener("click", function (event) {
    document.querySelector(".p-search__bg").classList.toggle("-open");
    document.querySelector(".p-search__filter").classList.toggle("-open");
  });
  if (!clickTarget_btn) {
    return
  }
  clickTarget_btn.addEventListener("click", function (event) {
    document.querySelector(".p-search__bg").classList.toggle("-open");
    document.querySelector(".p-search__filter").classList.toggle("-open");
  });
};


const handleQuestion = () => {
  const accordionContainer = document.querySelector(".p-faq__block");
  if (!accordionContainer) {
    return
  }
  accordionContainer.querySelectorAll('.p-faq__item').forEach(function(element) {
    element.addEventListener("click", function (event) {
      this.classList.toggle("-open");
    });
  });
};

const handleHowto = () => {
  const tabs = document.querySelectorAll(".c-howto__tab");
  if (!tabs) {
    return
  }
  tabs.forEach(element => {
    element.addEventListener("click", event => {
      tabs.forEach(element => {
        element.classList.add("c-howto__disabled");
      });
      element.classList.remove("c-howto__disabled");

      const attribute = element.getAttribute("data-tab");
      const blocks = document.querySelectorAll(".p-howto__block");
      const block = document.querySelector(attribute);
      blocks.forEach(element => {
        element.classList.add("c-howto__disabled");
      });
      block.classList.remove("c-howto__disabled");
    });
  });
}

const handleModal = () => {
  const clickModalOpen = document.querySelector(".js-modalOpen");
  if (!clickModalOpen) {
    return
  }
  clickModalOpen.addEventListener("click", function (event) {
    document.querySelector(".l-modal").classList.toggle("l-modal__hidden");
    document.querySelector(".l-overlay").classList.toggle("l-modal__hidden");
  });

  const clickModalCloses = document.querySelectorAll(".js-modalClose");
  if (!clickModalCloses) {
    return
  }
  clickModalCloses.forEach(element => {
    element.addEventListener("click", function (event) {
      document.querySelector(".l-modal").classList.toggle("l-modal__hidden");
      document.querySelector(".l-overlay").classList.toggle("l-modal__hidden");
    });
  });
}

const handleLevelDropDowns = () => {
  const items = document.querySelectorAll(".p-form__dropdownItem");
  if (!items) {
    return
  }
  const elements = document.getElementsByName('wanted[level_required]');
  if (elements.length == 0) {
    return
  }
  const fromDropDown = document.querySelector(".js-FromLevelDropDown");
  const toDropDown = document.querySelector(".js-ToLevelDropDown");
  if (!fromDropDown && !toDropDown) {
    return
  }
  // elements[1].checked : 選択肢「なし」
  // elements[2].checked : 選択肢「あり」

  // 初回画面読み込み時の施策
  if (elements[1].checked == true) {
    fromDropDown.disabled = true;
    toDropDown.disabled = true;
  }
  if (elements[2].checked == true) {
    fromDropDown.disabled = false;
    toDropDown.disabled = false;
  }

  // ラジオボタンが変化した時の施策
  elements.forEach(el => {
    el.addEventListener("change", event => {
      if (elements[1].checked == true) {
        fromDropDown.disabled = true;
        toDropDown.disabled = true;
      }
      if (elements[2].checked == true) {
        fromDropDown.disabled = false;
        toDropDown.disabled = false;
      }
    });
  });
}

const handleLevelDropDownsEqual = () => {
  const from_elements = document.getElementsByName('wanted[from_level_id]');
  const to_elements = document.getElementsByName('wanted[to_level_id]');
  if (from_elements.length == 0 || to_elements.length == 0) {
    return
  }
  // ドロップダウン（左）が変化した時の施策
  from_elements[0].addEventListener("change", event => {
    const num = from_elements[0].selectedIndex;
    to_elements[0].options[num].selected = true;
  });
}

const handleTextareaAutoHeight = () => {
  const textarea = document.querySelector(".js-textareaAutoHeight");
  if (!textarea) {
    return
  }
  // textareaの初期の高さを取得
  const clientHeight = textarea.clientHeight;
  textarea.addEventListener("input", function (event) {
    // textareaの要素の高さを設定（rows属性で行を指定するなら「px」ではなく「auto」で良いかも！）
    textarea.style.height = clientHeight + 'px';
    // textareaの入力内容の高さを取得
    const scrollHeight = textarea.scrollHeight;
    // textareaの高さに入力内容の高さを設定
    textarea.style.height = scrollHeight + 'px';
  });
}

const handleScrollBottom = () => {
  const article = document.querySelector('.js-messageArticle');
  if (!article) {
    return
  }
  // スクロールバーの位置をコンテンツ領域の最下部に設定
  article.scrollTo(0, article.scrollHeight);
}

const getCookie = (cookieName) => {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [key,value] = el.split('=');
    cookie[key.trim()] = value;
  });

  return cookie[cookieName];
}

const handleCounterTeamNames = () => {
  const elements = document.getElementsByName('team_id');
  const element = document.getElementById('team_names');

  if (elements.length == 0 || element == '' || element == null) {
    return
  }
  // 各変数の初期化
  let el = document.querySelector('.l-modal__teamNames');
  let team_names = getCookie('team_names');
  let ary_names = Array();
  let str_names = '';

  if (team_names == '' || team_names == null) {
    el.innerHTML = '選択されていません';
    return
  }

  ary_names = team_names.split(',');
  if (ary_names.length > 4) {
    let str_first = ary_names.slice(0, 3);
    let str_last = ary_names.slice(3);

    str_names = `${str_first}...他${str_last.length}チーム`;
  } else {
    str_names = team_names;
  }

  el.innerHTML = str_names;
}

const handleCounterReceivers = () => {
  const elements = document.getElementsByName('team_id');
  if (elements.length == 0) {
    return
  }
  // 各変数の初期化
  let ary = Array();
  let el = document.querySelector('.p-teamList__amountCounter');
  let team_ids = getCookie('team_ids');
  if (team_ids == '' || team_ids == null) {
    el.innerHTML = 0;
    return
  }
  ary = JSON.parse(`[${team_ids}]`);
  el.innerHTML = ary.length;
}

const handleMaxReceiversWarning = () => {
  const elements = document.getElementsByName('team_id');
  const element = document.querySelector('.l-modal__teamWaring');
  if (elements.length == 0 || !element) {
    return
  }
  // 各変数の初期化
  let ary = Array();
  let i = 0;
  let team_ids = getCookie('team_ids');
  if (team_ids == '' || team_ids == null) {
    i = 0;
    return
  }
  ary = JSON.parse(`[${team_ids}]`);
  i = ary.length;

  if (i > 24) {
    element.classList.remove("l-modal__hidden");
  } else {
    element.classList.add("l-modal__hidden");
  }
}

const handleIsReceivers = () => {
  const elements = document.getElementsByName('team_id');
  if (elements.length == 0) {
    return
  }
  // 各変数の初期化
  let ary = Array();
  let team_id = null;
  let team_ids = getCookie('team_ids');
  // チェックボックス x Cookie による checked 有無の施策
  elements.forEach(el => {
    team_id = parseInt(el.value, 10);

    if (team_ids == '' || team_ids == null) {
      return
    }

    ary = JSON.parse(`[${team_ids}]`);
    if (ary.includes(team_id)) {
      el.checked = true;
    }
  });
}

const handleReceivers = () => {
  const elements = document.getElementsByName('team_id');
  if (elements.length == 0) {
    document.cookie = 'team_ids=; max-age=0';
    document.cookie = 'team_names=; max-age=0';
    return
  }
  // 各変数の初期化
  let ary_ids = Array();
  let ary_names = Array();
  let team_id = null;
  let team_name = null;
  let team_ids = getCookie('team_ids');
  let team_names = getCookie('team_names');
  let hidden_team_ids = document.getElementById('team_ids');
  // 各値を設定
  if (team_ids != '' || team_ids != null) {
    hidden_team_ids.value = team_ids;
  }
  // チェックボックスが変化した時の施策
  elements.forEach(el => {
    el.addEventListener("change", event => {
      team_id = parseInt(el.value, 10);
      team_name = el.dataset.teamname;

      if (team_ids == '' || team_ids == null) {
        // 各値を設定
        document.cookie = `team_ids=${team_id};`;
        team_ids = getCookie('team_ids');
        document.cookie = `team_names=${team_name};`;
        team_names = getCookie('team_names');

        hidden_team_ids.value = team_ids;

        // カウンター表示を更新
        handleCounterReceivers();
        // Toのチーム名を更新
        handleCounterTeamNames();
        // 最大チーム数の注釈を更新
        handleMaxReceiversWarning();

        return false;
      }

      ary_ids = JSON.parse(`[${team_ids}]`);
      if (ary_ids.includes(team_id)) {
        ary_ids = ary_ids.filter(e => e != team_id);
      } else {
        ary_ids.push(team_id);
      }

      ary_names = team_names.split(',');
      if (ary_names.includes(team_name)) {
        ary_names = ary_names.filter(e => e != team_name);
      } else {
        ary_names.push(team_name);
      }

      // 各値を設定
      document.cookie = `team_ids=${ary_ids.toString()};`;
      team_ids = getCookie('team_ids');
      document.cookie = `team_names=${ary_names.toString()};`;
      team_names = getCookie('team_names');

      hidden_team_ids.value = team_ids;

      //カウンター表示を更新
      handleCounterReceivers();
      // Toのチーム名を更新
      handleCounterTeamNames();
      // 最大チーム数の注釈を更新
      handleMaxReceiversWarning();
    });
  });
}

const smoothScroll = () => {
  var clickTarget = document.querySelector("#js-scrollBtn");
  if (!clickTarget) {
    return
  }
  clickTarget.addEventListener("click", function (event) {
    document.querySelector("#js-scrollTarget").scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  });
};

const setupTeamCreateFormPrefectureHandler = () => {
  const prefectureInput = document.querySelector("#team_prefecture_id");
  const cityInput = document.querySelector("#team_city_id");
  if (!prefectureInput || !cityInput) {
    return;
  }

  cityInput.disabled = !prefectureInput.value || prefectureInput.value === '';
  prefectureInput.addEventListener("change", function (event) {
    cityInput.disabled = true;
    axios
      .get("/cities", { params: { prefecture_id: this.value } })
      .then(response => {
        cityInput.disabled = false;
        cityInput.innerHTML = response.data.map(
          city => `<option value="${city[1]}">${city[0]}</option>`
        );
      })
      .catch(function (error) {
        // error
      });
  });
};

const setupIndexOrderChangeHandler = () => {
  const form = document.querySelector(".js-wantedList__sort");
  if (!form) {
    return;
  }
  const select = form.querySelector("select");
  select.addEventListener("change", event => {
    form.submit();
  });
};

const setupSearchFormHandler = () => {
  const form = document.querySelector(".js-aside-search-form");
  if (!form) {
    return;
  }
  form.querySelectorAll("select").forEach(element => {
    element.addEventListener("change", event => {
      form.submit();
    });
  });
  form
    .querySelector('input[type="text"]')
    .addEventListener("blur", function (event) {
      if (this.value !== getParameterByName("query")) {
        form.submit();
      }
    });

  form
    .querySelectorAll('.p-search__checkbox input[type="checkbox"]')
    .forEach(element => {
      element.addEventListener("change", event => {
        form.submit();
      });
    });

  form
    .querySelectorAll('.p-search__radio input[type="radio"]')
    .forEach(element => {
      element.addEventListener("change", event => {
        form.submit();
      });
    });

  const maxItemsCount = 5;
  const prefectureItems = Array.from(
    form.querySelectorAll(".js-search__filterItemWrap")
  );
  const cityItems = Array.from(
    form.querySelectorAll(".p-search__filterItem")
  )

  const seemore = form.querySelector(".js-search-area-fileter-seemore");
  if (cityItems.length > maxItemsCount) {
    cityItems
      .slice(maxItemsCount, cityItems.length)
      .forEach(elem => {
        elem.style.display = "none";
      });
    prefectureItems.forEach(elem => {
      // 中身の要素がすべてdisplay: noneかどうか
      const displays = Array.from(elem.querySelectorAll('.p-search__filterItem')).map(li => li.style.display)
      if (displays.length === displays.filter(d => d === 'none').length) {
        elem.style.display = "none";
      }
    })

    seemore.addEventListener("click", function (event) {
      this.parentNode
        .querySelectorAll(".p-search__filterItem, .js-search__filterItemWrap")
        .forEach(elem => {
          elem.style.display = null;
        });
      this.style.display = "none";
    });
  } else {
    seemore.style.display = "none";
  }
};

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
